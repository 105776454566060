import { useState, useEffect, useContext } from 'react';
import { ScoreStateContext } from '../State/ScoreContext';
import { QuizStateContext } from '../State/QuizContext';
import { calculateScore } from '../Util';
import useQueryParameters from './useQueryParameters';

export default function useScoreSender() {

	const fetchAbortController = new AbortController();
	const { location, finalTime, startTime, QuizID } = useContext(QuizStateContext);
	const { numCorrect } = useContext(ScoreStateContext);
	let [response, setResponse] = useState(null);
	const UserID = useQueryParameters('UserID');

	const getEnterDate = (startTime: number) => {
		if (!startTime){
			var backup = Date.now();
		}
		let month = new Date(startTime ?? backup).getMonth() + 1;
		let day = new Date(startTime ?? backup).getDate();
		let year = new Date(startTime ?? backup).getFullYear();
		return month + "/" + day + "/" + year;
	}

	const getEnterTime = (startTime: number) => {
		if (!startTime){
			var backup = Date.now();
		}
		let hour = new Date(startTime ?? backup).getHours() % 12;
		let min = new Date(startTime ?? backup).getMinutes();
		let AMPM = " AM";
		if (new Date(startTime ?? backup).getHours() >= 12){
			AMPM = " PM";
		}
		return hour + ":" + min + AMPM;
	}

	const getQueryString = () => {
		let str = "?"; 
		str += "UserID=" + UserID;
		str += "&QuizID=" + QuizID;
		str += "&EnterDate=" + getEnterDate(startTime);
		str += "&EnterTime=" + getEnterTime(startTime);
		str += "&QuizTime=" + Number(finalTime / 1000).toFixed(1);
		str += "&QuizScore=" + numCorrect;
		str += "&CalcScore=" + calculateScore(finalTime, numCorrect);

		return str;
	}

	const sendScore = async () => {
		let path = 'http://www.hcasc.com/games/db_writequizresults.asp';
		if (process.env.REACT_APP_MODE === 'dev'){
			path = `http://localhost:8080/score`;
		}
		path += getQueryString();
		let response = await fetch(path, { signal: fetchAbortController.signal });
		let json = await response.json();
		setResponse(json);
	}

	useEffect(() => {
		if (location === 'end'){
			sendScore();
		} else {
			setResponse(null);
		}
		return () => fetchAbortController.abort();
	}, [location]);

	return response;
}