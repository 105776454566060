import React from 'react';
import styled from 'styled-components';

const parsePadding = (style) => {
	const vertPad = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
	const horzPad = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
	return { vertPad, horzPad }
}

const getElementInnerDimensions = (element) => {
	if (element){
		const style = window.getComputedStyle(element);
		let height = element.clientHeight;
		let width = element.clientWidth;
		const { vertPad, horzPad } = parsePadding(style);
		width -= horzPad;
		height -= vertPad;
		return { width, height };
	}
	return { width: 0, height: 0 };
}


const computeSize = (props) => {
	const element = props.element;
	const length = props.children.length;
	const { width, height } = getElementInnerDimensions(element);
	let fontSize = Math.sqrt((width * height) / length);
	return Math.min(fontSize, height * 0.25);
}

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: clamp(400px,120vh,1000px);
	box-sizing: border-box;
	color: #fff;
	margin: 0 auto;
	font-weight: 500;
`

interface TextProps {
	element: any
	children: any,
	height?: string,
	padding?: string
}

const Text = styled<TextProps | any>(Row)`
  font-size: ${computeSize}px;
  height: ${props => props.height ? props.height:"var(--question-height)"};
`

interface ResponsiveTextProps {
	height?: string,
	padding?: string
}

export const ResponsiveText: React.FC<ResponsiveTextProps> = ({ children, height, padding }) => {

	const ref = React.useRef();
	const [element, setElement] = React.useState();

	React.useEffect(() => {
		if (ref.current != undefined){
			setElement(ref.current);
		}
	}, [ref]);

	return (
		<Text ref={ref} element={element} height={height} padding={padding}>
			{ children }
		</Text>
	)
}