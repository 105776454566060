import React, { useReducer, useMemo } from 'react';

const initialSizingState = {
	contentHeight: null,
}

const timerReducer = (state, action) => {
	switch (action.type){
		case 'setContentHeight':
			return { ...state, contentHeight: action.payload }
		default:
			return { ...state };
	}
}

const SizingStateContext = React.createContext<any>(initialSizingState);
const SizingDispatchContext = React.createContext<any>(initialSizingState);

const SizingContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(timerReducer, initialSizingState);
	const context = useMemo(() => {
		return {state, dispatch}
	}, [state, dispatch]);
	return (
		<SizingDispatchContext.Provider value={context.dispatch}>
			<SizingStateContext.Provider value={context.state}>
				{ children }
			</SizingStateContext.Provider>
		</SizingDispatchContext.Provider>
	)
}

export {
	SizingStateContext,
	SizingDispatchContext,
	SizingContextProvider
};
