import React, { useReducer, useMemo } from 'react';

const initialScoreState = {
	numCorrect: 0
}

const scoreReducer = (state, action) => {
	switch (action.type){
		case 'plusOne':
			return { ...state, numCorrect: state.numCorrect + 1 }
		case 'clear':
			return { ...state, numCorrect: 0 }
		default:
			return state;
	}
}

const ScoreStateContext = React.createContext<any>(initialScoreState);
const ScoreDispatchContext = React.createContext<any>(initialScoreState);

const ScoreContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(scoreReducer, initialScoreState);
	const context = useMemo(() => {
		return {state, dispatch}
	}, [state, dispatch]);
	return (
		<ScoreDispatchContext.Provider value={context.dispatch}>
			<ScoreStateContext.Provider value={context.state}>
				{ children }
			</ScoreStateContext.Provider>
		</ScoreDispatchContext.Provider>
	)
}

export {
	ScoreStateContext,
	ScoreDispatchContext,
	ScoreContextProvider
};
