import React from 'react';
import { IonList, IonItem, IonLabel, IonListHeader, IonTitle, IonSkeletonText } from '@ionic/react';
import { QuizListProps } from '../Types';
import { Container, Row } from './componentStyles';
import useQueryParameters from '../hooks/useQueryParameters';

const ItemStyles = {
	margin: '10px 0'
}

const QuizList: React.FC<QuizListProps> = ({ list }) => {

	const UserID = useQueryParameters('UserID');

	const getSkeletonItems = (num: number = 3) => {
		let items = [];
		const makeItem = () => {
			let percent = Math.floor(Math.random() * 30) + 30 + "%";
			let style = {
				width: percent
			}
			return (
				<IonItem>
					<IonLabel>
						<IonSkeletonText animated style={style}></IonSkeletonText>
					</IonLabel>
				</IonItem>
			);
		}
		for (let i = 0; i < num; i++){
			items.push(makeItem());
		}
		return items;
	}

	const mapList = () => {
		if (list.length <= 0){
			return getSkeletonItems();
		}
		return list.map((each, index) => {
			let params = (UserID) ? `?UserID=${UserID}`:"";
			return (
				<IonItem 
					key={each.name}
					routerLink={`quiz/${each.name}${params}`} 
					style={ItemStyles}
				>
					<IonLabel>{each.displayName}</IonLabel>
				</IonItem>
			);
		});
	}

	return (
	<Container>
		<IonList
			inset={false}
			lines="full"
			color="warning"
		>
			<IonListHeader>
				<IonTitle>Quizzes</IonTitle>
			</IonListHeader>
			{mapList()}
		</IonList>
	</Container>
	);
  
};

export default QuizList;
