import React, { useEffect, useContext } from 'react';
import { convertSeconds, getRoundedSeconds } from '../Util';
import { TimerStateContext, TimerDispatchContext } from '../State/TimerContext';
import { Circle, OuterCircle } from './componentStyles';
import useWindowDimensions from '../hooks/useWindowDimensions';

const Timer: React.FunctionComponent = () => {

	const { seconds, elapsed, active, handle } = useContext(TimerStateContext);
	const dispatch = useContext(TimerDispatchContext);
	const { width } = useWindowDimensions();
	const maxOuterDiameter = 140;
	const minOuterDiameter = 75;
	

	useEffect(() => {
		let interval = null;
		if (active && !handle) {
			let handle = setInterval(() => {
				dispatch({ type: 'tick' })
			}, 1000);
			dispatch({ type: 'setHandle', payload: handle });
		}
		return () => clearInterval(interval);
	}, [handle, active, seconds, dispatch]);

	const getOuterDiameter = () =>{
		let diameter = width * 0.12;
		if (diameter > maxOuterDiameter){
			return maxOuterDiameter;
		} else if (diameter < minOuterDiameter){
			return minOuterDiameter;
		} else {
			return diameter;
		}
	}

	const getInnerDiameter = () => {
		let diameter = getOuterDiameter() * 0.8;
		return diameter;
	}

	return (
		<>
			<OuterCircle diameter={getOuterDiameter()} clip="true"></OuterCircle>
			<Circle diameter={getInnerDiameter()}>{convertSeconds(getRoundedSeconds(elapsed))}</Circle>
		</>
	);
};

export default Timer;