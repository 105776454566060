import React from 'react';
import { Line, DivOnLine } from './componentStyles';

interface QuestionLineProps {
	number?: number
	showLine?: boolean
	color?: string
}

const QuestionLine: React.FC<QuestionLineProps> = ({ number, showLine = true, color = "#fff" }) => {
	return (
		<Line showLine={showLine} color={color}>
			<DivOnLine color={color}>Question #{number}</DivOnLine>
		</Line>
	)
}

export default QuestionLine;