import React from 'react';
import { OptionRow } from './componentStyles';
import { OptionProps } from '../Types';
import { IonIcon } from '@ionic/react';
import { closeCircleOutline, checkmarkCircleOutline } from "ionicons/icons";



const Option: React.FC<OptionProps> = ({ index, value, onPick, optionState }) => {

	const getIconStyles = () => {
		return {
			color: 'white', fontSize: 'clamp(20px, 2vh, 200px)'
		}
	}

	const getIcon = () => {
		switch(optionState){
			case 'picked_correct':
				return (<IonIcon icon={checkmarkCircleOutline} style={getIconStyles()}></IonIcon>);
			case 'picked_wrong':
				return (<IonIcon icon={closeCircleOutline} style={getIconStyles()}></IonIcon>);
			case 'not_picked_correct':
				return (<IonIcon icon={checkmarkCircleOutline} style={getIconStyles()}></IonIcon>);
			default:
				return null;
		}
	}

	return (
		
		<OptionRow
			state={optionState}
			onClick={() => {
				let potentialEndTime = Date.now();
				onPick(index, potentialEndTime);
			}}
		>
			{value}
			{ getIcon() }
		</OptionRow>
		
	)
}
export default Option;