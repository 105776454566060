import React, { useReducer, useMemo } from 'react';
import { getNext } from '../Util';

// quiz states
type Location = "start" | "middle" | "end";

const initialQuizState = {
	quiz: null, // entire quiz object parsed from xml
	interaction: null, // encapsulates one quiz question, expect several to a quiz
	usedInteractionIndexes: [],
	sessionUsed: [],
	interactionNumber: null,
	question: null,
	correctAnswer: null, //index of correct answer for current interaction
	options: [], // array of options for the current interaction
	image: null,
	totalInteractionsAllowed: null,
	loading: false,
	location: "start" as Location, // one of three quiz states
	finalTime: null, // seconds as recorded by the timer
	endTime: null, // timestamp at quiz end
	startTime: null, // timestamp at quiz start
}

const quizReducer = (state, action) => {
	switch (action.type){
		case 'setQuiz':
			if (action.payload){
				return {
					...state,
					quiz: action.payload,
					totalInteractionsAllowed: action.payload.total_interactions_per_play,
					QuizID: action.payload.QuizID,
					quizName: action.payload.company_name
				}
			} else {
				return {
					...state,
					interaction: null,
					usedInteractionIndexes: [],
					sessionUsed: [],
					interactionNumber: null,
					question: null,
					correctAnswer: null,
					options: [],
					image: null
				}
			}
		case 'setInteraction':
			return { ...state, interaction: action.payload }
		case 'setLoading':
			return { ...state, loading: action.payload }
		case 'setLocation':
			return { ...state, location: action.payload }
		case 'getNext':
			if (state.quiz){
				let { sessionUsed, used, newIndex } = getNext(state.sessionUsed, state.usedInteractionIndexes, state.quiz.interactions.length);
				let newInteraction = state.quiz.interactions[newIndex];
				return {
					...state,
					interaction: newInteraction,
					usedInteractionIndexes: used,
					interactionNumber: used.length,
					question: newInteraction.question,
					correctAnswer: newInteraction.correctanswer,
					options: newInteraction.options,
					image: newInteraction.image_component,
					sessionUsed: sessionUsed
				}
			} else {
				return {
					...state,
					interaction: null
				}
			}
		case 'end':
			let finalTime = action.payload.end - state.startTime;
			return {
				...state,
				location: "end",
				finalTime: finalTime,
				endTime: action.payload.end,
			}
		case 'start':
			if (state.quiz){
				let { sessionUsed, used, newIndex } = getNext(state.sessionUsed, [], state.quiz.interactions.length);
				let newInteraction = state.quiz.interactions[newIndex];
				return {
					...state,
					location: 'middle',
					interaction: newInteraction,
					usedInteractionIndexes: used,
					interactionNumber: used.length,
					question: newInteraction.question,
					correctAnswer: newInteraction.correctanswer,
					options: newInteraction.options,
					image: newInteraction.image_component,
					totalInteractionsAllowed: state.quiz.total_interactions_per_play,
					startTime: Date.now(),
					sessionUsed: sessionUsed
				}
			} else {
				return state;
			}
		case 'quit': 
			return {
				...state,
				location: 'start',
				interaction: null,
				usedInteractionIndexes: [],
				interactionNumber: null,
				question: null,
				correctAnswer: null,
				options: [],
				image: null,
				startTime: null,
			}
		case 'playAgain':
			return {
				...state,
				location: 'start',
				startTime: null,
			}
		default:
			return state;
	}
}

const QuizStateContext = React.createContext<any>(initialQuizState);
const QuizDispatchContext = React.createContext<any>(initialQuizState);

const QuizContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(quizReducer, initialQuizState);
	const context = useMemo(() => {
		return {state, dispatch}
	}, [state, dispatch]);
	return (
		<QuizDispatchContext.Provider value={context.dispatch}>
			<QuizStateContext.Provider value={context.state}>
				{ children }
			</QuizStateContext.Provider>
		</QuizDispatchContext.Provider>
	)
}

export {
	QuizStateContext,
	QuizDispatchContext,
	QuizContextProvider
};
