import { 
	IonContent,
	IonPage,
	useIonViewWillEnter,
	useIonViewDidEnter,
} from '@ionic/react';
import React, { useContext } from 'react';
import './Page.css';
import Loading from '../components/Loading';
import { QuizPageProps } from '../Types';
import Score from '../components/Score';
import Header from '../components/Header';
import { Container } from '../components/componentStyles';
import { QuizStateContext } from '../State/QuizContext';
import { Begin } from '../components/Begin';
import InteractionDisplay from '../components/InteractionDisplay';
import useXMLFetcher from '../hooks/useXMLFetcher';
import { SizingDispatchContext } from '../State/SizingContext';
import useScoreSender from '../hooks/useScoreSender';
import { setPageTitle } from '../Util';
import useQueryParameters from '../hooks/useQueryParameters';

const QuizPage: React.FC<QuizPageProps> = ({ match, history }) => {

	const ref = React.useRef(null);
	const dispatch = useContext(SizingDispatchContext);
	const { location, loading, quizName } = useContext(QuizStateContext);
	const response = useScoreSender();
	useXMLFetcher(match.params.name);
	const UserID = useQueryParameters('UserID');

	React.useEffect(() => {
		setPageTitle(quizName);
		dispatch({ type: 'setContentHeight', payload: ref.current.clientHeight })
	}, [quizName])

	const getLocation = () => {
		switch (location){
			case 'start':
				return <Begin />
			case 'middle':
				return (loading) ? <Loading title="Loading"/>:<InteractionDisplay />
			case 'end':
				return <Score playAgain={true} />
			default: 
				return <Begin />
		}
	}
	
	return (
		<IonPage
			style={{padding: '0'}}
		>
			<Header
				title={JSON.stringify(match.params.id)}
				quit={true}
				history={history}
			/>
			<IonContent>
				<Container ref={ref}>
					{ getLocation() }
				</Container>
			</IonContent>
		</IonPage>
	);
};

export default QuizPage;
