import React, { useReducer, useMemo } from 'react';

const initialTimerState = {
	seconds: 0,    // rounded seconds
	elapsed: 0,    // elapsed milliseconds (only updates on tick)
	start: null,   // timestamp when timer becomes active
	end: null,     // timestamp when timer becomes inactive
	active: false, // boolean (true if timer is active)
	handle: null,  // setInterval handle
}

const timerReducer = (state, action) => {
	switch (action.type){
		case 'tick':
			if (state.active && state.handle){
				return { ...state, seconds: state.seconds + 1, elapsed: Date.now() - state.start }
			} else {
				return { ...state }
			}
		case 'activate': 
			return { 
				...state, 
				active: true, 
				start: Date.now(),
				elapsed: 0
			}
		case 'deactivate':
			clearInterval(state.handle);
			return { 
				...state, 
				active: false, 
				handle: null, 
				end: Date.now(), 
				elapsed: Date.now() - state.start,
				seconds: 0
			}
		case 'clear':
			return { 
				...state, 
				seconds: 0 
			}
		case 'setHandle': 
			if (state.handle){
				clearInterval(state.handle);
			}
			return { ...state, handle: action.payload }
		default:
			return { ...state };
	}
}

const TimerStateContext = React.createContext<any>(initialTimerState);
const TimerDispatchContext = React.createContext<any>(initialTimerState);

const TimerContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(timerReducer, initialTimerState);
	const context = useMemo(() => {
		return {state, dispatch}
	}, [state, dispatch]);
	return (
		<TimerDispatchContext.Provider value={context.dispatch}>
			<TimerStateContext.Provider value={context.state}>
				{ children }
			</TimerStateContext.Provider>
		</TimerDispatchContext.Provider>
	)
}

export {
	TimerStateContext,
	TimerDispatchContext,
	TimerContextProvider
};
