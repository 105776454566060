import React from 'react';
import { IonSpinner, IonText } from '@ionic/react';
import { Row } from './componentStyles';
import QuizImage from './QuizImage';
interface LoadingProps {
	title?: string
}
const Loading: React.FC<LoadingProps> = ({title}) => {
	return (
		<div>
			<QuizImage />
			<Row justify="flex-start" align="flex-end" style={{ padding:'10px 60px' }}>
				<IonText color="light" style={{
					display:'inline-block', 
					marginRight: '5px',
					marginBottom: '5px',
					fontSize: '24px'
				}}>
					{(title) ? title:""}
				</IonText>
				<IonSpinner name="dots" color="light"/>
			</Row>
		</div>
	)
}
export default Loading;