import React, { useContext } from 'react'
import QuizImage from './QuizImage'
import { Form, Question } from './componentStyles'
import { IonButton } from '@ionic/react'
import { QuizDispatchContext } from '../State/QuizContext'
import { TimerDispatchContext } from '../State/TimerContext'
import useWindowDimensions from '../hooks/useWindowDimensions'

export const Begin: React.FC = () => {
	const quizDispatch = useContext(QuizDispatchContext);
	const timerDispatch = useContext(TimerDispatchContext);
	const { height} = useWindowDimensions();
	const introText = "Get ready to begin the quiz";

	return (
		<div>
			<QuizImage />
			<Form>
				<Question length={introText.length} height={height}>{introText}</Question>
				<IonButton color="success" onClick={
					() => {
						timerDispatch({ type: 'clear' })
						timerDispatch({ type: 'activate' })
						quizDispatch({ type: 'start' })
					}}
				>Start Quiz</IonButton>
			</Form>
		</div>
	)
}