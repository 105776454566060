import useWindowDimensions from "../hooks/useWindowDimensions";
import React from "react";
import { IonImg } from "@ionic/react";
import { ImageContainer } from './componentStyles'

interface QuizImageProps {
	src?: string,
	maxHeight?: number | string
}

const QuizImage: React.FC<QuizImageProps> = ({ src, maxHeight }) => {
	let default_path = '/games/default/resources/stars-default.jpg';

	const baseImageStyles = {
		margin: '0 auto',
	}

	const { width } = useWindowDimensions();
	const getImageStyle = () => {
		if (width > 764){
			return { ...baseImageStyles, padding: "0 60px" };
		} else {
			return { ...baseImageStyles, padding: "0 10px" };
		}
	}
	return (
		<ImageContainer maxHeight={maxHeight}>
			<img
				src={src ?? default_path}
				onError={(e: any) => {
					if (src !== default_path){
						e.target.src = default_path;
					} else {
						e.target.style.display = "none";
					}
				}}
				style={getImageStyle()}
			/>
		</ImageContainer>
	)
}
export default QuizImage;