import { useState, useEffect } from 'react';
import { sluggify, getPath } from '../Util';

export default function useQuizList(path: string) {

	const fetchAbortController = new AbortController();
	const [json, setJson] = useState([]);

	const sortAlphaNum = (a, b) => {
		var reA = /[^a-zA-Z]/g;
		var reN = /[^0-9]/g;
		var aA = a.replace(reA, "");
		var bA = b.replace(reA, "");
		if (aA === bA) {
			var aN = parseInt(a.replace(reN, ""), 10);
			var bN = parseInt(b.replace(reN, ""), 10);
			return aN === bN ? 0 : aN > bN ? 1 : -1;
		} else {
			return aA > bA ? 1 : -1;
		}
	}

	const readDir = async () => {
		let response = await fetch(path, { signal: fetchAbortController.signal });
		if (!response.ok){
			throw new Error("Failed with HTTP code " + response.status);
		}
		let games_array = await response.json();
		Promise.all(games_array.map(async (game: string, index: number) => {
			let displayName = await getDisplayName(getPath(sluggify(game)));
			return { 'name': game, 'displayName': displayName || game, 'id': index };
		})).then((data) => {
			setJson(data);
		})
	}

	const getDisplayName = async (path: string) => {
		let response = await fetch(path, { signal: fetchAbortController.signal });
		if (!response.ok){
			throw new Error("Failed with HTTP code " + response.status);
		}
		let xml = await response.text();
		let dom =  new DOMParser().parseFromString(xml, "application/xml");
		let company_name = getQuizAttribute(dom, 'company_name');
		return company_name;
	}

	const getQuizAttribute = (dom: any, attr: string) => {
		let quiz = dom.getElementsByTagName('quiz');
		if (quiz.length){ 
			quiz = quiz[0]; 
		} else {
			return null;
		}
		let attributes = quiz.attributes;
		return (attributes[attr]) ? attributes[attr].value:null;
	}

	useEffect(() => {
		readDir().catch((e) => { console.log(`%c${e}`, 'background: crimson; color: #333;padding:10px;')});
		return () => fetchAbortController.abort();
	}, [path]);

	return json;
}