import React, { useContext } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import styled from 'styled-components';
import { QuizStateContext, QuizDispatchContext } from '../State/QuizContext';
import Timer from './Timer';
import useWindowDimensions from '../hooks/useWindowDimensions';
import '../pages/Page.css';
import { ScoreBadge } from './ScoreBadge';
import { ScoreDispatchContext, ScoreStateContext } from '../State/ScoreContext';
import { MainImage } from './componentStyles';

interface HeaderProps {
	title: string
	quit?: boolean
	history?: any
}

const QuitButton = styled(IonButton)`
	font-size: clamp(10px, 3vw, 13px);
`;

const quitButtonStyles = {
	'--border-radius': '3px',
	'fontSize': 'clamp(10px, 3vw, 13px)',
	'height': 'clamp(20px, 4vw, 33px)',
	'cursor': 'pointer'
}

const Header: React.FC<HeaderProps> = ({ quit, history }) => {

	const { width } = useWindowDimensions();
	const { location, totalInteractionsAllowed } = useContext(QuizStateContext);
	const quizDispatch = useContext(QuizDispatchContext);
	const scoreDispatch = useContext(ScoreDispatchContext);
	const { numCorrect } = useContext(ScoreStateContext);

	return (
	<IonHeader>
		{(width > 768) ? (
		<IonToolbar>
			<MainImage
				onClick={() => {
					history.push('/');
					quizDispatch({ type: 'quit' });
					scoreDispatch({ type: 'clear'});
				}}
				src="/assets/hcasc-logo.png"
			/>
			<IonButtons slot="primary">
				{
					(location === 'middle') ? <ScoreBadge />:null
				}
				{
					quit && 
					<QuitButton
						fill="solid"
						size="large"
						color="danger" 
						routerLink="/home"
						onClick={() => {
							quizDispatch({ type: 'quit' });
							scoreDispatch({ type: 'clear'});
						}}
						style={quitButtonStyles}
					>
						Quit
					</QuitButton>
				}
			</IonButtons>
		</IonToolbar>
		)
		:
		(<IonToolbar>
				{
					(location === 'middle') ? <div
					style={{ 
						textTransform: 'uppercase',
						display: 'inline-block',
						position: 'absolute',
						bottom: '12px',
						fontWeight: 500,
						fontSize: 'clamp(10px, 3vw, 13px)'
					}}
				>
					Score: { numCorrect } / { totalInteractionsAllowed }
				</div>:null
				}
			<IonButtons slot="primary">
				{
					quit && 
					<QuitButton
						fill="solid"
						size="large"
						color="danger" 
						routerLink="/home"
						onClick={() => {
							quizDispatch({ type: 'quit' });
							scoreDispatch({ type: 'clear'});
						}}
						style={quitButtonStyles}
					>
						Quit
					</QuitButton>
				}
			</IonButtons>
			<MainImage
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -60%)'
				}}
				onClick={() => {
					history.push('/');
					quizDispatch({ type: 'quit' });
					scoreDispatch({ type: 'clear'});
				}}
				src="/assets/hcasc-logo.png"
			/>
		</IonToolbar>)
		}
		{
			(location === 'middle') ? (<Timer />):null
		}
	</IonHeader>
	)
}
export default Header;