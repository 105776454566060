import React from 'react'
import { TimerContextProvider } from './TimerContext';
import { ScoreContextProvider } from './ScoreContext';
import { QuizContextProvider } from './QuizContext';
import { SizingContextProvider } from './SizingContext';
export const AppContextProvider = ({ children }) => (
	<QuizContextProvider>
		<ScoreContextProvider>
			<TimerContextProvider>
				<SizingContextProvider>
					{ children }
				</SizingContextProvider>
			</TimerContextProvider>
		</ScoreContextProvider>
	</QuizContextProvider>
)
