import React, { useContext } from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonRow } from '@ionic/react';
import { convertSeconds, getSeconds, getRoundedSeconds, calculateScore } from '../Util';
import { ScoreReport } from './componentStyles';
import { ScoreProps } from '../Types'
import { ScoreStateContext, ScoreDispatchContext } from '../State/ScoreContext';
import { TimerDispatchContext, TimerStateContext } from '../State/TimerContext';
import { QuizStateContext, QuizDispatchContext } from '../State/QuizContext';

const RowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '300px',
}

const Score: React.FC<ScoreProps> = ({ playAgain = false}) => {

	const { numCorrect } = useContext(ScoreStateContext);
	const { quiz, finalTime } = useContext(QuizStateContext);
	const quizDispatch = useContext(QuizDispatchContext);
	const scoreDispatch = useContext(ScoreDispatchContext);
	const timerDispatch = useContext(TimerDispatchContext);

	const getFinalScore = () => {
		if (quiz && quiz.total_interactions_per_play){
			return (
				<IonRow style={RowStyle}>
					<div>Final Score:</div>
					<div>{ numCorrect } / { quiz.total_interactions_per_play}</div>
				</IonRow>
			);
		} else {
			return ""
		}
	}

	const getFinalTime = () => {
		return (
			<IonRow style={RowStyle}>
				<div>Total Time:</div>
				<div>{ convertSeconds(getRoundedSeconds(finalTime)) }</div>
			</IonRow>
		)
	}

	const makeRow = (label, value) => {
		return (
			<IonRow style={RowStyle}>
				<div>{ label }</div>
				<div>{ value }</div>
			</IonRow>
		)
	}

	return (
		<IonCard style={{margin: 0}}>
			<IonCardHeader>
				<IonCardTitle>{ quiz ? quiz.quiz_completion_message:"" }</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<ScoreReport>
					{ makeRow('Number Correct', numCorrect + " / " + quiz.total_interactions_per_play) }
					{ makeRow('Time Taken', convertSeconds(getRoundedSeconds(finalTime))) }
					{ makeRow('Total Score', calculateScore(finalTime, numCorrect)) }
				</ScoreReport>
				<IonRow>
					<IonButton
						color="warning"
						routerLink="/"
						onClick={() => {
							quizDispatch({ type: 'quit' });
							scoreDispatch({ type: 'clear' })
						}}
					>
						Home
					</IonButton>
					{ (playAgain) ? (
							<IonButton
								color="success"
								onClick={() => {
									quizDispatch({ type: 'playAgain' });
									scoreDispatch({ type: 'clear' });
									timerDispatch({ type: 'deactivate' });
								}}
							>
								Play Again?
							</IonButton>
						): null 
					}
				</IonRow>
			</IonCardContent>
		</IonCard>
	)
}

export default Score;