import styled from 'styled-components';
import {
	OptionRowProps,
	CircleProps,
	LineProps,
	RowProps,
	QuestionStyleProps,
	ImageContainerProps,
	OptionsContainerProps
} from '../Types';
import { isPlatform } from '@ionic/react';

export const Form = styled.div`
	padding: var(--form-top-padding) 60px var(--form-bottom-padding);
	height: var(--form-inner-height);
	@media (max-width: 768px) {
		padding: var(--form-top-padding) 10px var(--form-bottom-padding);
	}
`

export const MainImage = styled.img`
	width: clamp(130px,15vw,200px);
	padding: 10px;
`;

export const Question = styled.div<QuestionStyleProps>`
	font-size: ${(props) => {
		let base = (1 - (props.length / 100)) * 10;
		base = Math.max(1.2, Math.min(base, 100));
		let clampString = `clamp(14px, ${base}vw, 30px)`;
		return clampString;
	}};
	color: #fff;
	margin: 10px 0 10px;
	font-weight: 500;
	max-height: 130px;
`

export const Line = styled.div<LineProps>`
	height: 0;
	position: relative;
	border-bottom: ${(props) => {
		return (props.showLine) ? `1px solid ${props.color};`:`none`
	}};
	width: 100%;
	display: flex;
	align-items: center;
	color: ${(props) => props.color};
`;

export const DivOnLine = styled.div<LineProps>`
	position: absolute;
	left: 0;
	color: ${(props) => props.color};
	background: var(--ion-color-primary);
	padding: 0 10px 0 0;
	text-transform: uppercase;
	font-size: 12px;
`;

export const Answer = styled.input`
	padding: 6px 10px;
	color: #333;
	font-weight: 600;
	border: none;
	border: 1px solid #333;
	border-radius: 3px;
`

export const Row = styled.div<RowProps>`
	display: flex;
	flex-direction: row;
	align-items: ${(props) => {
		return (props.align) ? props.align:`center`
	}};
	flex: 1;
	justify-content: ${(props) => {
		return (props.justify) ? props.justify:`center`
	}};
	padding: 20px;
	span {
		margin-right: 20px;
		font-size: 16px;
	}
`

export const Container = styled.div`
	width: 100%;
	max-width: clamp(400px, 120vh, 1000px);
	height: var(--content-inner-height);
	margin: auto;
	padding: var(--content-vertical-padding) 60px;
	@media (max-width: 768px) {
		padding: var(--content-vertical-padding) 10px;
	}
`

export const ImageContainer = styled.div<ImageContainerProps>`
	max-height: ${(props) => (props.maxHeight) ? props.maxHeight + 'px':'var(--image-height)'};
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const OptionsContainer = styled.div<OptionsContainerProps>`
	height: ${(props) => (props.height) ? props.height + 'px':'var(--options-total-height)'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const OptionRow = styled.div<OptionRowProps>`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-size: var(--option-font-size);
	font-weight: 600;
	padding: var(--option-padding);
	line-height: var(--option-line-height);
	height: var(--option-height);
	margin: var(--option-vertical-margin) 0 0;
	transition: background 0.3s;
	border-radius: var(--option-radius);
	cursor: pointer;
	box-sizing: border-box;
	color: ${(props) => {
		switch(props.state){
			case 'picked_correct':
				return "var(--ion-color-success-contrast)";
			case 'picked_wrong':
				return "var(--ion-color-danger-contrast)";
			case 'not_picked_correct':
				return "var(--ion-color-success-contrast)";
			default:
				return "var(--ion-color-light-contrast)";
		}
	}};
	background: ${(props) => {
		switch(props.state){
			case 'picked_correct':
				return "var(--ion-color-success)";
			case 'picked_wrong':
				return "var(--ion-color-danger)";
			case 'not_picked_correct':
				return "var(--ion-color-success)";
			default:
				return "#E1E1E1";
		}
	}};
	:hover {
		background: ${(props) => {
			switch(props.state){
				case 'picked_correct':
					return "var(--ion-color-success)";
				case 'picked_wrong':
					return "var(--ion-color-danger)";
				case 'not_picked_correct':
					return "var(--ion-color-success)";
				default:
					return (isPlatform('mobile')) ? "#E1E1E1":"#FCF3CE";
			}
		}}
	}
`;

export const OuterCircle = styled.div<CircleProps>`
	width: ${props => props.diameter}px;
	height: ${props => props.diameter}px;
	border-radius: ${props => props.diameter}px;
	background: var(--ion-color-primary);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--ion-color-light);
	font-weight: 600;
	letter-spacing: 2px;
	font-size: ${props => props.diameter * 0.25}px;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -34%);
	z-index: 11;
	clip-path: ${props => (props.clip === 'true') ? 'polygon(0px 34.5%, 100% 34.5%, 100% 100%, 0px 100%)':'none'};
`;

export const Circle = styled.div<CircleProps>`
	width: ${props => props.diameter}px;
	height: ${props => props.diameter}px;
	border-radius: ${props => props.diameter}px;
	background: var(--ion-color-success);
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--ion-color-light);
	font-weight: 500;
	letter-spacing: 2px;
	font-size: clamp(12px, 2vw, 28px);
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, -30%);
	z-index: 11;
`;

export const ScoreReport = styled.div`
	color: #000;
	font-size: 20px;
	padding: 0 0 10px 0;
`;
