import React, { useState, useEffect, useContext} from 'react';
import { Form, OptionsContainer } from './componentStyles';
import Option from './Option';
import QuestionLine from './QuestionLine';
import QuizImage from './QuizImage';
import { QuizStateContext, QuizDispatchContext } from '../State/QuizContext';
import { ScoreDispatchContext } from '../State/ScoreContext';
import { TimerStateContext, TimerDispatchContext } from '../State/TimerContext';
import { ResponsiveText } from './ResponsiveText';

const InteractionDisplay: React.FC = () => {

	const [choiceIndexInState, setChoiceIndexInState] = useState(null);
	const [canChoose, setCanChoose] = useState(true);
	const { interactionNumber, usedInteractionIndexes, correctAnswer, 
		totalInteractionsAllowed, options, question, image } = useContext(QuizStateContext);
	const quizDispatch = useContext(QuizDispatchContext);
	const scoreDispatch = useContext(ScoreDispatchContext);
	const timerDispatch = useContext(TimerDispatchContext);

	useEffect(() => {
		setChoiceIndexInState(null);
		setCanChoose(true);
	}, [interactionNumber])
	
	const getOptionState = (optionIndex: number): string => {
		if (choiceIndexInState === null){
			return "";
		}
		if (choiceIndexInState === correctAnswer && optionIndex === choiceIndexInState){
			return 'picked_correct';
		} else if (choiceIndexInState !== correctAnswer && optionIndex === correctAnswer) {
			return 'not_picked_correct';
		} else if (choiceIndexInState !== correctAnswer && optionIndex === choiceIndexInState){
			return 'picked_wrong';
		}
		return "";
	}

	const score = (choiceIndex: number) => {
		if (choiceIndex === correctAnswer){
			scoreDispatch({ type: 'plusOne' })
		}
	}

	const getOptions = () => {
		if (options){
			return options.map((option: string, optionIndex: number) => {
					let optionState = getOptionState(optionIndex);
					return (<Option 
						key={optionIndex}
						index={optionIndex}
						value={option}
						onPick={(choiceIndex: number, end: number) => {
							if (canChoose){
								setCanChoose(false);
								setChoiceIndexInState(choiceIndex);
								score(choiceIndex);
								let delay = 1.3 * 1000;
								if (usedInteractionIndexes.length >= totalInteractionsAllowed){
									timerDispatch({ type: 'deactivate' });
									let end = Date.now();
									setTimeout(() => {
										quizDispatch({ type: 'end', payload: { end  } });
									}, delay);
								} else {
									setTimeout(() => {
										quizDispatch({ type: 'getNext' });
									}, delay);
								}
							}
						}}
						optionState={optionState}
					/>)
				}
			);
		}
	}

	return (
		<div style={{height: '100%'}}>
			{image ?? <QuizImage />}
			<Form>
				<QuestionLine number={interactionNumber} showLine={true} color="#E1E1E1" />
				<ResponsiveText>
					{question}
				</ResponsiveText>
				<OptionsContainer>
					{ getOptions() }
				</OptionsContainer>
			</Form>
		</div>
	);
};

export default InteractionDisplay;