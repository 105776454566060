import React, { useContext } from 'react';
import { IonBadge } from '@ionic/react';
import { ScoreStateContext } from '../State/ScoreContext';
import { QuizStateContext } from '../State/QuizContext';

export const ScoreBadge = () => {

	const { numCorrect } = useContext(ScoreStateContext);
	const { totalInteractionsAllowed } = useContext(QuizStateContext);

	return (
		<IonBadge 
			color="success"
			style={{ 
				marginRight: '5vw',
				padding: "10px 16px",
				textTransform: "uppercase",
				fontWeight: '500',
				letterSpacing: '1px', 
				cursor: 'default'
			}}
		>
			Score: { numCorrect } / { totalInteractionsAllowed }
		</IonBadge>
	)
}
