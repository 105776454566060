import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect } from 'react';
import QuizList from '../components/QuizList';
import Header from '../components/Header';
import './Page.css';
import useQuizList from '../hooks/useQuizList';
import { setPageTitle } from '../Util';
import useQueryParameters from '../hooks/useQueryParameters';

interface HomePageProps {
	history: any
}

const HomePage: React.FC<HomePageProps> = ({ history }) => {

	const list_path = (process.env.REACT_APP_MODE === 'dev') ? "./games/list.json":"./list.php";
	const list = useQuizList(list_path);
	
	useEffect(() => {
		document.title = `${process.env.REACT_APP_TITLE} | Home`;
	}, [])

	useIonViewWillEnter(() => {
		setPageTitle('Home');
	})

	return (
		<IonPage>
			<Header 
				title="College Bowl Quiz"
				history={history}
			/>
			<IonContent>
				<QuizList list={list} />
			</IonContent>
		</IonPage>
	);
};

export default HomePage;
