import { useEffect, useContext } from 'react';
import { QuizDispatchContext } from '../State/QuizContext';
import { QuizProps, Interaction } from '../Types';
import QuizImage from '../components/QuizImage';
import React from 'react';
import { sluggify, getPath, setPageTitle } from '../Util';

export default function useXMLFetcher(name: string) {

	const param_ref_name = name;

	const dispatch = useContext(QuizDispatchContext);
	const fetchAbortController = new AbortController();
	/*
	*   Function Summary:
	*   Runs when the component loads and on any quiz name change
	*   1. gets the quiz information
	*   2. parses the information to js object
	*   3. adds the object to state
	*/
	useEffect(function(){
		setPageTitle(name);
		dispatch({ type: 'setLoading', payload: true });
		getQuizInformation(
			getPath(
				sluggify(name)
			)
		).then((quiz) => {
			if (quiz.interactions !== undefined){
				dispatch({ type: 'setQuiz', payload: quiz });
				dispatch({ type: 'setLoading', payload: false });
			}
		})
		return () => { 
			// kill any async actions
			fetchAbortController.abort();
		}
	}, [name]);

	/*
	* Function Summary:
	* 
	*   Gets the quiz information from a file,
	*   parses it into a Document object, ande
	*   parses that into a js object
	*
	* Parameters: None
	* 
	* Return: Document Object holding xml quiz data
	* 
	*/
	const getQuizInformation = async (path: string) => {
		let response = await fetch(path, { signal: fetchAbortController.signal });
		if (!response.ok){
			throw new Error("Failed with HTTP code " + response.status);
		}
		let xml = await response.text();
		let dom =  new DOMParser().parseFromString(xml, "application/xml");
		let quiz = parseDom(dom);
		return quiz;
	}

	/*
	* Function Summary:
	* 
	*   Parses quiz Document object
	*   and return more usable javascript object
	* 
	* Parameters: Document Object parsed from xml
	* 
	* Return: JavaScript Object representing quiz data
	* 
	*/
	const parseDom = (dom: any): QuizProps => {
		let obj = {} as QuizProps;
		obj['name'] = name;
		obj['QuizID'] = name;

		// get quiz attributes
		let quiz = dom.getElementsByTagName('quiz');
		if (quiz.length){ quiz = quiz[0]; }
		let attributes = quiz.attributes;
		for (let attr in attributes){
			let name = attributes[attr].name;
			let value = attributes[attr].value;
			if (attr === 'interactions_per_play'){
				value = parseInt(value);
			}
			if (attr === 'company_name'){
				setPageTitle(attributes[attr].value);
			}
			if (value){
				obj[name] = value;
			}
		}

		// get the interactions
		obj.interactions = [];
		let interactions = dom.getElementsByTagName('interaction');
		for (let i = 0; i < interactions.length; i++){
			let newInteraction = createInteraction(interactions[i]);
			obj.interactions.push(newInteraction);
		}

		return obj;
	}

	/*
	* Function Summary:
	* 
	*   Creates the iteraction portion of the quiz object
	* 
	* Parameters: Document Node with tagName 'interaction'
	* 
	* Return: JavaScript Object representing interaction data
	* 
	*/
	const createInteraction = (interaction: any): Interaction => {
		let obj = {} as Interaction;

		// get interaction attributes
		let attributes = interaction.attributes;
		for (let attr in attributes){
			const { name, value } = attributes[attr];
			if (value){
				obj[name] = value;
			}
			if (name === 'resource_path'){
				let img = new Image();
				let update_value = value.replace('.', '/games/' + sluggify(param_ref_name));
				img.src = update_value;
				obj['image_component'] = (<QuizImage src={img.src} />);
			}
		}

		// add question
		let question = interaction.getElementsByTagName('question');
		obj.question = question[0].childNodes[0].data;

		let correctanswer = interaction.getElementsByTagName('correctanswer');
		obj.correctanswer = parseInt(correctanswer[0].childNodes[0].data);

		// add answers
		let options = interaction.getElementsByTagName('option');
		let optionsArr = new Array<string>();
		for (let i = 0; i < options.length; i++){
			optionsArr.push(options[i].childNodes[0].data);
		}
		obj.options = optionsArr;

		return obj;
	}
}