export const convertSeconds = (seconds: number) => {
	// get mins and secs
	let mins = Math.floor(seconds / 60);
	let secs = seconds % 60;

	// format display
	let minsStr = (mins < 10) ? `0${mins}`:mins;
	let secsStr = (secs < 10) ? `0${secs}`:secs;
	return `${minsStr}:${secsStr}`;
}

// this mimics the functionality of 
// ActionScript's int() fn previously in use
export const actionScriptInt = (param: number) => {
	return (param > 0) ? Math.floor(param):Math.ceil(param);
}

export const getSeconds = (elapsed) => {
	return elapsed / 1000;
}

export const getRoundedSeconds = (elapsed) => {
	return Math.floor(getSeconds(elapsed));
}

export const getActionSeconds = (start: number, end?: number) => {
	if (end === undefined){
		return (actionScriptInt((start) / 100) / 10);
	} else {
		return (actionScriptInt((end - start) / 100) / 10);
	}
}

export const calculateScore = (finalTime, numCorrect) => {
	finalTime = parseFloat(Number(finalTime / 1000).toPrecision(2));
	if (finalTime >= 100){
		return numCorrect * 100;
	} else {
		let temp1 = 100 - finalTime;
		let temp2 = numCorrect * 100;
		let temp3 = 1 + temp1 / 100;
		return actionScriptInt(temp2 * temp3);
	}
}

export const sluggify = (name: string) => {
	return name.toLowerCase().replace(' ', '-');
}

export const getPath = (filename: string) => {
	return '/games/' + filename + '/questions.xml';
}

export const getNext = (sessionUsed: number[], used: number[], allowed: number) => {
	let possible = [];
	for (let i = 0; i < allowed; i++){
		possible.push(i);
	}
	if (sessionUsed.length >= allowed){
		sessionUsed = [];
	}
	possible = possible.filter(each => used.indexOf(each) < 0);
	possible = possible.filter(each => sessionUsed.indexOf(each) < 0);
	let newIndex = possible[Math.floor(Math.random() * possible.length)];
	return {
		sessionUsed: [...sessionUsed, newIndex],
		used: [...used, newIndex],
		newIndex,
	}
}

export const setPageTitle = (title: string) => {
	document.title = `${process.env.REACT_APP_TITLE} | ${title}`;
}